@import '../../../../components/KittyGameView/styles/common';

.welcome-nav {
	@include noselect();

	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;

	width: 100%;
	color: white;

	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0 1rem;

	&.--has-notch {
		bottom: 1rem;
	}

	&.--back-btn-visible {
		.inner {
			&.--back {
				pointer-events: all;
				opacity: 1;
				transform: translate(0%, 0%);
			}

			&.--main {
				pointer-events: none;
				opacity: 0;
				transform: translate(0%, 50%);
			}
		}
	}

	.inner {
		width: 100%;
		max-width: 576px;
		position: absolute;
		// right: 0;
		// left: 0;
		// transform: translateX(-50%);
		bottom: 0;

		padding: 0 .33rem;
		
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include transition-all(.2s);

		&.--back {
			pointer-events: none;
			justify-content: center;
			opacity: 0;
			transform: translate(0%, 50%);
			
			abbr {
				filter:drop-shadow(0px 0px 6px #000);

				opacity: 0.8;

				padding: .625rem 1rem 0.75rem;

				width: 3rem;
    			height: 3rem;
				border: 3px solid white;
				border-radius: 50%;
				background: rgba(0,0,0,0.5);
				transform: translateY(-.75rem);

				// display: flex;
				justify-content: center;

				svg {
					margin: 0;
				}
			}
		}
		

		// Using <abbr> because React complains about using <a href="#"> without a valid anchor
		abbr {
			// padding: 0.625rem 1rem 0.75rem;
			padding: 0rem 1rem 0.75rem;
			cursor: pointer;

			text-shadow: 3px 3px 3px #000;

			&, &:hover, &:active, &:focus {
				text-decoration: none;
			}

			display: flex;
			flex-direction: column;
			align-items: center;

			// the font-awesome icon
			svg {
				margin: 0.25rem 0 0.05rem;
				filter:drop-shadow(3px 3px 3px #000);
			}

			label {
				cursor: pointer;
				margin: 0;
			}

			// .icon-wrap is needed for when we put a badge on it,
			// like for the friends notifications count
			.icon-wrap {
				position: relative;
				filter:drop-shadow(3px 3px 3px #000);

				svg {
					filter: none;
				}

				.badge {
					position: absolute;
					top: -.25rem;
					right: -.75rem;
					
					background: rgba(255,0,0,1);
					color: white;
					text-shadow: none;

					padding: 0rem;
					border-radius: 50%;
					width: 1.25rem;
					height: 1.25rem;
					
					display: flex;
					align-items: center;
					justify-content: center;
					
				}
			}


			&, & .badge {
				transition: transform .15s cubic-bezier(0.425, -0.600, 0.700, 1.520), //cubic-bezier(.42, 0, .58, 1),
					color .15s cubic-bezier(0.425, -0.600, 0.700, 1.520), //cubic-bezier(.42, 0, .58, 1),
					font-size 0s;
			}

			&.active {
				color: yellow;
				// filter:drop-shadow(3px 3px 3px #000);
				transform: scale(1.33);
					
				.badge {
					transform: scale(.625);
				}
			}
		}
	}
	
}