.stars {
	font-size: .75rem;

	transform: translateY(-.075rem);

	
	text-shadow: 2px 2px 3px #000;
	// -webkit-text-stroke: 1px black;

	label {
		margin: 0;
	}

	.num {
		color: yellow;
		display: inline-block;
		margin-left: .125rem;
	}

	// color: black;
	svg { 
		// color: black;
		color: yellow;
		filter:drop-shadow(3px 3px 3px #000);
		margin: 0 !important;

		transform: scale(.7) translateY(-.05rem);// translateX(2rem);
		// margin-right: -.8rem;
		margin-left: inherit;
	}
}