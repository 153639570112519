/* Most of the loginBtn stules from https://codepen.io/davidelrizzo/pen/vEYvyv */

@import '../../../components/KittyGameView/styles/common';
@import './login-btn';

.login-wrap {

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.no-fb-btn {
		margin: 1rem 4rem;
		max-width: 80%;
	}
}

#takeover-prompt {
	position: fixed;
	top: 1rem;
	right: 1rem;
	opacity: 0.5;
}

.testing-login {
	color: rgba(255,255,255,1);
	font-size: 1.5rem;
	text-shadow: 3px 3px 5px #000;
	text-align: center;
	max-width: 80%;
}

.login-needed {
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	text-shadow: 3px 3px 5px #000;

	button {
		margin: 1rem 0;
	}

	.benefits {
		font-size: 1.1rem;
		text-align: center;
		max-width: 80%;
	}
}
