.settings-widget {
	justify-content: center;
	align-items: center;
	position: relative;

	display: flex;
	flex-direction: column;

	.settings-header {
		opacity: 0;
		margin: 0 !important;
		// set height by px since actor graphic in pixi is not 
		// relative to DPI
		height: 192px !important;
		margin-bottom: 1.5rem !important;
	}

	.form-group.volume-setting {
		min-width: 80%;

		.rc-slider {
			.rc-slider-track {
				// height: .25rem;
			}
			.rc-slider-handle {
				width: 1.75rem;
				height: 1.75rem;
				// margin-top: calc(-(1.5rem/2) + 2px); // height of track is 4px
				margin: 0;
				transform: translate(calc(-50% + 2px), calc(-50% + 2px));
			}
		}
	}

	.customize-kitty-btn {
		font-size: .85rem;
		margin: .5rem auto;
	}

	.customize-kitty-popup {
		z-index: 99999;
		position: absolute;
		bottom: 0;
		right: 0;
		top: 250px;
		left: 0;
		// background: white;
		// box-shadow: 0 -3px 15px #000000;
		padding: 1rem .25rem;
		overflow: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: flex-start;

		$mask: linear-gradient(to bottom,
			rgba(0, 0, 0, 0) 0rem,
			rgba(0, 0, 0, 1) .25rem,
			rgba(0, 0, 0, 1) 90%,
			rgba(0, 0, 0, 0) 100%);

		mask-image: $mask;
		-webkit-mask-image: $mask;

		h1 {
			margin:0;
			text-align: center;
		}



		.btn {
			font-size: .85rem;
			margin: .5rem auto;
			// margin: .75rem;
			// padding: 1rem;
			// flex: 0;
			// display: flex;
			// flex-direction: row;
			// align-items: center;
			// justify-content: center;
			// line-height: 0;

			// .custom-btn-inner {
			// 	transform: translateY(-50%);
			// }
		}

		.colorpicker {
			position: relative;
			// width: 14em;
			width: calc(100% - 1.75rem);
			min-height: 7em;
			margin: .5rem 1rem;
			background-color: #fff;
			border: 1px solid rgba(0,0,0,0.2);
			border-radius: 0.25em;
			// box-shadow: 0 3px 6px rgba(0,0,0,0.3);
			box-shadow: 3px 3px 15px black;

			  .map {
				position: absolute;
				top: .5em;
				bottom: .5em;
				right: 2em;
				left: .5em;
				overflow: hidden;
				user-select: none;
				border-radius: 0.25em;
			  
				&.active {
				  cursor: none;
				}
			  
				&.dark .pointer {
				  border-color: #fff;
				}
			  
				&.light .pointer {
				  border-color: #000;
				}
			  
				.pointer {
				  position: absolute;
				  width: 10px;
				  height: 10px;
				  margin-left: -5px;
				  margin-bottom: -5px;
				  border-radius: 100%;
				  border: 1px solid #000;
				  will-change: left, bottom;
				}
			  
				.background {
				  top: 0;
				  left: 0;
				  position: absolute;
				  height: 100%;
				  width: 100%;
				}
			  
				.background:before,
				.background:after {
				  display: block;
				  content: '';
				  position: absolute;
				  top: 0;
				  left: 0;
				  bottom: 0;
				  right: 0;
				}
			  
				.background:after {
				  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
				}
			  
				.background:before {
				  background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
				}
			  
			  }
			  
			  .slider {
				position: absolute;
				user-select: none;
			  
				&.vertical {
				  top: 0;
				  bottom: 0;
				  left: 50%;
				  width: 10px;
				  cursor: ns-resize;
			  
				  .track {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 50%;
					width: 8px;
					margin-left: -4px;
				  }
				}
			  
				&.horizontal {
				  left: 0;
				  right: 0;
				  top: 50%;
				  height: 10px;
				  cursor: ew-resize;
			  
				  .track {
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					height: 8px;
					margin-top: -4px;
				  }
				}
			  
				.track {
				  border-radius: 3px;
				  background: #888;
				}
			  
				.pointer {
				  position: absolute;
				  bottom: 50%;
				  left: 50%;
				  width: 16px;
				  height: 16px;
				  margin-left: -8px;
				  margin-bottom: -8px;
				  border-radius: 16px;
				  background: #fff;
				  box-shadow: inset 0 0 0 1px #ccc,0 1px 2px #ccc;
				  will-change: left, bottom;
				}
			  
			  }
			  
			  .hue-slider {
				position: absolute;
				top: 1em;
				bottom: 1em;
				right: 1.3em;
			  
				.track {
				  background: linear-gradient(to bottom,
					#FF0000 0%,
					#FF0099 10%,
					#CD00FF 20%,
					#3200FF 30%,
					#0066FF 40%,
					#00FFFD 50%,
					#00FF66 60%,
					#35FF00 70%,
					#CDFF00 80%,
					#FF9900 90%,
					#FF0000 100%
				  );
				}
			  
			  }
			  
			  .opacity-slider {
				position: absolute;
				bottom: 1.3em;
				left: 1em;
				right: 2em;
			  
				.slider {
				  background: #fff url('data:image/gif;base64,R0lGODdhEAAQAPEAAMvLy8zMzP///wAAACwAAAAAEAAQAEACHYxvosstCAEMrq6Jj812Y59NIDQipdY5XLWqH4sVADs=') repeat;
				  background-size: 8px 8px;
				  height: 8px;
			  
				  .track {
					height: 8px;
					margin-top: -4px;
					background: linear-gradient(to right,
					  rgba(255,255,255,0) 0%,
					  #FFF 100%
					);
				  }
				}
			  
			  }
			  
			  .with-opacity-slider {
				height: 14em;
			  
				.map {
				  bottom: 2em;
				}
			  
				.hue-slider {
				  bottom: 2em;
				}
			  }
		}
	}
}
