.friends-widget {
	.fw-padding {
		padding: .75rem .5rem 1rem;

		&.--hasNotch {
			padding-top: 1.75rem;
		}
	}

	input {
		border-radius: 1rem;
	}

	h1 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		
		padding-left: .75rem; // left-align with content in table since <td> default pad is .75rem
		// margin-bottom: 4rem;
	}

	table {
		width: 100%;
		// max-width: 90%;

		// th {
		// 	padding: .625rem .75rem;
		// }

		// td {
		// 	padding: .625rem;
		// }

		th, td {
			padding: .625rem;
		}

		td {
			padding: .33rem .625rem;
		}

		tr:nth-child(even) td {
			background: rgba(255,255,255,0.075);

			$radius: 1rem;
			&:first-child {
				border-top-left-radius:     $radius;
				border-bottom-left-radius:  $radius;
			}

			&:last-child {
				border-top-right-radius:    $radius;
				border-bottom-right-radius: $radius;
			}
		}
	}

	td.approval-btns {
		// display: flex;
		// justify-content: center;
		// flex-direction: column;
		vertical-align: middle;

		padding-right: 0;

		.approve {
			// color: yellow;
		}

		.decline {
			// color: red;
		}
	}

	td.waiting-person {
		text-align: left !important;
	}

	.friend-req-pending {
		filter: opacity(.625);
	}

	.friend-req-notify {
		// color: red;
		// border-radius: .25rem;
		// padding: .1rem .25rem;
		filter: opacity(.85);
		color: yellow;
	}
}