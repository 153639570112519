@import '../../../../../components/KittyGameView/styles/common';
@import '../../../../../components/KittyGameView/styles/_include-media';

@mixin card-button {
	color: white;
	text-decoration: none;

	font-size: .85rem;
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 0, 0.25);

	border-radius: .25rem;
	padding: .125rem .25rem;

	svg {
		color: yellow;
		margin: 0 .25rem 0 0;
	}
}


.popup {
	position: absolute;
	top: 0rem;
	left: 0;
	bottom: -3rem;
	right: 0;
	z-index: 100;

	background: var(--theme-color);

	@media (min-width: 575px) {
		background: transparent;
	}

	@include transition-all(.5s);
	opacity: 0;
	pointer-events: none !important;

	&.visible {
		opacity: 1;
		pointer-events: all !important;
	}

	
	.closeAction {
		padding: 0.625rem 1rem 0.75rem;
		cursor: pointer;

		text-shadow: 3px 3px 3px #000;
		color: white;

		&, &:hover, &:active, &:focus {
			text-decoration: none;
		}

		&.closeAction {
			position:fixed;
			top: .5rem;
			right: .5rem;
			z-index: 25; // higher than nav
		}

		display: flex;
		flex-direction: column;
		align-items: center;

		// the font-awesome icon
		svg {
			margin: 0.25rem 0 0.05rem;
			filter:drop-shadow(3px 3px 3px #000);
		}



	}
}


$header-height: 300px;

.worldHeader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;


	.playCircle {
		position: absolute;
		// top: 44%;
		left: 50%;
		// top: $header-height / 2; //* .425;
		top: $header-height * .575; //* .425;

		transform: translate(-50%, -50%);
		font-size: 1.7rem;
		background: rgba(0,0,0,0.5);
		opacity: 0.8;
		border-radius: 50%;
		border: 3px solid white;
		width:  4rem;
		height: 4rem;

		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			transform: translateX(.2rem);// translateY(.05rem);
			color: white;
		}

		&.locked, &.replay {
			svg {
				transform: none;
			}
		}

	}

	.worldHeaderBg {
		position: absolute;
		top: 0;
		left: 50%;

		transform: translateX(-50%); //translateY(-20%);

		width: calc(576px + 2rem);
		height: $header-height * 1.5;

		object-fit: cover;


		$mask: linear-gradient(to bottom,
			rgba(0, 0, 0, 0) 0rem,
			rgba(0, 0, 0, 1) 0rem,
			rgba(0, 0, 0, 1) 62.5%,
			rgba(0, 0, 0, 0) 100%);

		mask-image: $mask;
		-webkit-mask-image: $mask;

	}

	.headerLabel {
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		// width: 4rem;
		// bottom: .2rem;
		height: $header-height;


		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		// align-content: flex-end;

		line-height: .8;

		// top: 0;
		// left: 50%;
		// transform: translateX(-50%);
		text-align: center;

		font-size: 2rem;
		// margin: 0 .25rem;
		padding: 0 .25rem .25rem .25rem;
	}
}

.worldPanel {

	overflow-y: auto;
	position: absolute;
	top: $header-height * .975;
	left: 0;
	bottom: 0; //3.25rem;
	// right: -17px;

	@media (min-width: 575px) {
		bottom: 3.25rem; // compensate for tx bg
	}

	text-shadow: 3px 3px 3px #000;


	z-index: 10;

	// hide scrollbars on 1.0 dpi devices
	right: -17px;
	padding-right: 17px;
	
	// undo the hiding on higher DPI devices
	@include media('retina2x') {
		right: 0;
		padding-right: 0;
	}

	// mask-image: linear-gradient(to bottom, transparent 25%, black 75%);

	$mask: linear-gradient(to bottom,
		rgba(0, 0, 0, 0) 0rem,
		rgba(0, 0, 0, 1) 1rem,
		rgba(0, 0, 0, 1) 95%,
		rgba(0, 0, 0, 0) 100%);

	mask-image: $mask;
	-webkit-mask-image: $mask;


}

.world {
	max-width: 576px;
	width: 100%;
	// min-height: calc(100% - 6.25rem);
	margin: 0rem auto 0rem;
	overflow: hidden;
	
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	padding: 1rem;

	// padding-top: 1rem;

	
	.levelList {
		// margin: 0 -1rem;
		padding-bottom: 3.25rem; // compensate for HomeNavBar

		svg {
			margin: 0;
		}

		.chart {
			svg {
				filter: none;
			}
		}

		// reset
		ul, li {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		ul {
			margin-top: 1rem;
		}

		li {
			margin-left: 0;// -2.25rem;

			display: flex;
			flex-direction: row;
			svg {
				margin: .25rem .5rem 0 0;
			}

			.completion, .time, .hard {
				width: 3rem;
				text-align: right;
				font-size: .85rem;
				margin: .125rem 0 0 0;

				svg {
					// margin: 0 .33rem 0 0;
					margin: 0 0 0 0;
					transform: translateY(-.125rem);
				}

				// background:white;
			}

			.time {
				// background: blue;
				width: 4rem;
			}

			.name {
				// margin-left: .1rem;
				width: 4rem;
				// background:red;
			}

			&.completed {
				opacity: 0.5;
				svg { //}, .completion, .name {
					// color: $brand-secondary;
					color: yellow;
				}
			}

			&.current {
				svg { //}, .completion, .name {
					color: yellow;
				}

				box-shadow: 0 0 0 0.2rem rgba(255, 255, 0, 0.25);

				border-radius: .25rem;
				padding: .125rem .25rem;

				margin: .25rem 0 .25rem -.25rem;
			}


			&.locked {
				opacity: .8;

				.completion {
					text-align: center;
				}
			}
		}

		.backBtn {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			box-shadow: 0 0 0 0.2rem rgba(255, 255, 0, 0.25);

			border-radius: .25rem;
			padding: .125rem .25rem;

			svg {
				color: yellow;
			}

			.label {
				width: 3.25rem;
				// text-align: center;
				// font-size: .85rem;
				margin: 0 0 0 .5rem;
			
			}
			
			
		}
	}

	&.unlocked .body .bodyLeft {
		width: 60%;
	}

	.body {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		// @include transition-all(.2s);
		// opacity: 0;
		// transform: translateX(-50%);

		// &.--visible {
		// 	opacity: 1;
		// 	transform: translateX(0);
		// }

		.bodyLeft {
			display: flex;
			flex-direction: column;
		}

		.bodyRight, .unlockHeader {
			// width: 40%;
			// margin-left: .5rem;
			color: white;

			display: flex;
			flex-direction: row;
			justify-content: space-between;

			line-height: 0.95;

			margin: 1rem 0 0rem;

			// background: rgba(0,0,0,0.5);
			// border-radius: .25rem;
			// padding: 1rem;

			svg {
				color: yellow;
				margin: 0 .25rem 0 0;
			}

			&.unlockHeader {
				flex-direction: column;
				padding: .5rem;

				.unlockDescription {
					text-align: center;
					font-size: .95rem;
					margin: .5rem 0 0;
				}


				.unlockBtn {
					@include card-button();

					cursor: pointer;

					label {
						margin: 0;
						cursor: pointer !important;
					}

					svg {
						// transform: translateY(.25rem);
					}

				
					// background: yellow;	
					background: rgba(0,0,0,0.5);
					box-shadow: 0 0 0 0.2rem rgba(255, 255, 0, 1.0);

					color: yellow;
					text-shadow: 3px 3px 3px #000;
					// -webkit-text-stroke: 1px black;

					text-align: center;

					// color: black;
					svg { 
						// color: black;
						color: yellow;
						filter:drop-shadow(3px 3px 3px #000);
						font-size: 1rem;
					}

					.unlockIcon {
						margin: 0 .5rem 0 0;
						transform: scale(1) translate(-.25rem, -.125rem);
					}

					.starIcon {
						transform: scale(.8) translate(-.25rem, -.125rem);
					}

					font-size: 1.1rem;

					padding: .25rem .625rem;
				}
					
			}

			.playBtn, .moreBtn, .worldCompletedNotice {
				@include card-button();

				&.moreBtn {
					margin: .75rem 0 .5rem;
				}

				&.worldCompletedNotice {
					background: rgba(0,0,0, 0.25);
					box-shadow: 0 0 0 0.2rem rgba(0,0,0, 0.25);
					// text-align: center;

					svg {
						color: white;
					}
				}

			}

			.timeInfo,
			.completionInfo,
			.rateInfo {
				width: 4rem;
				font-size: .75rem;
				// padding-left: .25rem; // better visual alignment with "all levels" button

				display: flex;
				flex-direction: column;

				.hv {
					font-size: .95rem;
				}

				.label {
					
				}


				svg {
					color: yellow;
					margin: 0 0 0 0;
				}
			}

			.completionInfo {
				text-align: center;
				// align-items: center;
				align-items: center;
				// justify-content: center;
			}

			.rateInfo {
				text-align: right;
			}
		}
	}

	display: flex;
	flex-direction: column;

	.header {
		display: flex;
		flex-direction: row;
		// justify-content: space-between;

		.left {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 3.25rem;

			margin: 0 .25rem 0 .25rem;

			.cost-content {
				margin-top: .5rem;

				label {
					font-size: .80rem;
				}

				svg {
					color: yellow;
					margin: -.75rem .25rem 0 -.5rem;
				}

				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				flex-wrap: nowrap;
			}
		}
	}

	svg {
		filter: drop-shadow(3px 3px 3px #000);
		margin: .625rem .5rem 0 0;
	}

	&.locked {
		h2, .description {
			filter: opacity(.4);
		}
	}

	h2, h3 {
		width: 100%;
		font-size: 1.75rem;
		line-height: 1.01;
	}

	.description {
		line-height: 1.01;
		text-align: center;
	}

	svg.redoIcon {
		margin: .4rem 0 0 .25rem !important;
	}

}