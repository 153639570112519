@import '../../../../components/KittyGameView/styles/common';
@import '../../../../components/KittyGameView/styles/_include-media';
@import '../../LoginScene/login-btn';

#welcome-widget {
	--pane-width: 375px; // set via javascript later to window.innerWidth
	
	opacity: 0; // hide until UI has a chance to set --pane-width

	width: var(--pane-width);
	overflow: auto;

	display: flex;
	flex-direction: column;

	height: 100%;

	.close-ads-button-overlay {
		background: white;
		// padding: 1rem;
		padding: .25rem .25rem 0rem .5rem;
		position: fixed;
		bottom: 0;
		right: 0;
		$tx: 0.75;
		border: 1px solid rgba(0,0,0, $tx);
		border-top-left-radius: 1rem;
		border-bottom-width: 0;
		border-right-width:  0;
		filter:drop-shadow(0px 0px 3px #000);

		svg {
			opacity: $tx;
		}
	}

	.welcome-scroller {
		
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		overflow-y: hidden;

		height: calc(100%);

		.pane {
			@include noselect();

			width: var(--pane-width);
			height: 100%;
			flex: 0 0 auto;
			

			color: white;
			text-shadow: 3px 3px 3px #000;

			background-position: 50% 50%;
			background-size: 200% 200%;
			object-fit: cover;

			// TODO: Move background to it's own element so we can rotate
			// @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

			display: flex;
			flex-direction: row;
			justify-content: center;
			// padding: 1rem 1rem;
			padding-bottom: 3rem;
			
			.inner {
				max-width: 576px;
				width: 100%;
				height: 100%;

				display: flex;
				flex-direction: column;
				align-items: center;

				position: relative;
				overflow: hidden;

				.content {
					overflow-y: auto;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					
					// hide scrollbars on 1.0 dpi devices
					right: -17px;
					padding-right: 17px;
					
					// undo the hiding on higher DPI devices
					@include media('retina2x') {
						right: 0;
						padding-right: 0;
					}

					// mask-image: linear-gradient(to bottom, transparent 25%, black 75%);

					$mask: linear-gradient(to bottom,
						rgba(0, 0, 0, 0) 0%,
						rgba(0, 0, 0, 1) 1rem,
						rgba(0, 0, 0, 1) 90%,
						rgba(0, 0, 0, 0) 100%);

					mask-image: $mask;
					-webkit-mask-image: $mask;					
				}
			}
			
			td, th {
				color: white;
				// border-color: rgba(255,255,255,0.5) !important;
				border-color: transparent !important;
				font-weight: normal;
				line-height: 1.1;
				text-align: right;
			}

			td.person,
			th.person {
				text-align: left;
				cursor: default;
			}

			thead {
				th {
					background: rgba(255,255,255,0.25);
				}

				th:first-child {
					border-top-left-radius: 1rem;
					border-bottom-left-radius: 1rem;
				}

				th:last-child {
					border-top-right-radius: 1rem;
					border-bottom-right-radius: 1rem;
				}
			}

			label {
				cursor: default;
			}
		}

		.settings-pane {
			.content {
				.content-padding {
				// .inner {

					display: flex;
					flex-direction: column;
					
					// Background image set in JSX
					justify-content: center;
					align-items: center;

					padding: 1rem 1rem 5rem 1rem;

					h1 {
						margin: 4rem 0 1.5rem;
					}

					.fb-connection-settings {
						margin-top: 1rem;

						display: flex;
						flex-direction: column;
						
						// Background image set in JSX
						justify-content: center;
						align-items: center;

						p {
							text-align: center;
							line-height: 1;
						}

					}

					.version-info {
						text-align: center;
						line-height: 1;
					}
				}
			}
			
		}

		.center-pane {
			cursor: pointer;
		}
	}
}