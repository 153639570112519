
.button {
	filter: drop-shadow(0px 0px 6px #000);

	color: white;
	text-shadow: 3px 3px 3px #000;

	// the font-awesome icon
	svg {
		filter:drop-shadow(3px 3px 3px #000);
		color: white;
	}

	width: 2rem;
	height: 2rem;
	border: 3px solid white;
	border-radius: 50%;
	background: rgba(0,0,0,0.5);
	
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;

	// margin: .75rem;

	.timerWrap {
		position: absolute;
		// top: -4px;
		// left: -4px;
		// width:  calc(100% + 8px);
		// height: calc(100% + 8px);

		top:  -3px;
		left: -3px;
		width:  calc(100% + 6px);
		height: calc(100% + 6px);
		border-radius: 50%;
		overflow: hidden;

		.timer {
			position: absolute;
			bottom: 0;

			width: 100%;
			height: 33%;
			background: rgba(255,255,255,0.75);
			// background: rgba(255,255,0,.5);

			// transition: height 1s/50 linear;
		}
	}

	// svg {
	// 	color: $brand-secondary;
	// 	@include transition-all(.2s);
	// 	transform: translateY(-100%) scale(3);
	// }

	// overflow: hidden;
}