@import '../../../components/KittyGameView/styles/common';
@import '../../../components/KittyGameView/styles/_include-media';

.shop {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	
	opacity: 0.0; // placeholder
	@include transition-all(.125s);
	&.visible {
		opacity: 1;
	}

	max-width: 576px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	overflow: hidden;
	
	nav {
		// position: fixed;
		position: absolute;
		// left: 0;
		// right: 0;
		// top: 0;
		z-index: 20;

		max-width: 576px;
		
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		
		width: 100%;

		padding: 2rem 1.5rem 0 1.5rem;
	}

	&.transparentBg {
		nav {
			padding: 4.5rem .5rem 0 .5rem;
		}
	}

	nav abbr, .closeAction {
		padding: 0.625rem 1rem 0.75rem;
		cursor: pointer;

		text-shadow: 3px 3px 3px #000;
		color: white;

		&, &:hover, &:active, &:focus {
			text-decoration: none;
		}

		&:not(.closeAction) {
			width: 4rem;
		}

		&.closeAction {
			position:fixed;
			top: .5rem;
			right: .5rem;
			z-index: 25; // higher than nav
		}

		display: flex;
		flex-direction: column;
		align-items: center;

		// the font-awesome icon
		svg {
			margin: 0.25rem 0 0.05rem;
			filter:drop-shadow(3px 3px 3px #000);
		}

		label {
			cursor: pointer;
			margin: 0;
		}

		// .icon-wrap is needed for when we put a badge on it,
		// like for the friends notifications count
		.icon-wrap {
			position: relative;
			filter:drop-shadow(3px 3px 3px #000);

			svg {
				filter: none;
			}

			.badge {
				position: absolute;
				top: -.25rem;
				right: -.75rem;
				
				background: rgba(255,0,0,1);
				color: white;
				text-shadow: none;

				padding: 0rem;
				border-radius: 50%;
				width: 1.25rem;
				height: 1.25rem;
				
				display: flex;
				align-items: center;
				justify-content: center;
				
			}
		}


		&, & .badge {
			transition: transform .15s cubic-bezier(0.425, -0.600, 0.700, 1.520), //cubic-bezier(.42, 0, .58, 1),
				color .15s cubic-bezier(0.425, -0.600, 0.700, 1.520), //cubic-bezier(.42, 0, .58, 1),
				font-size 0s;
		}

		&.active {
			color: yellow;
			// filter:drop-shadow(3px 3px 3px #000);
			transform: scale(1.33);
				
			.badge {
				transform: scale(.625);
			}
		}
	}

	&.transparentBg {
		.panelContainer {
			.panel {	
				mask-image: linear-gradient(to bottom,
					rgba(0, 0, 0, 0) 9.75rem,
					rgba(0, 0, 0, 1) 11rem,
					rgba(0, 0, 0, 1) 92%,
					rgba(0, 0, 0, 0) 99%);
	
				.panelBody {
					min-height: calc(100% - 3.5rem); // todo: why calc here?
					margin: 6rem auto 0rem;
					padding-bottom: 3.5rem;
				}
			}
		}
	}
	
	.panelContainer {
		.panel {

			overflow-y: auto;
			position: absolute;
			top: 0rem;
			left: 0;
			bottom: 0;
			// right: -17px;

			text-shadow: 3px 3px 3px #000;

	
			z-index: 10;

			// hide scrollbars on 1.0 dpi devices
			right: -17px;
			padding-right: 17px;
			
			// undo the hiding on higher DPI devices
			@include media('retina2x') {
				right: 0;
				padding-right: 0;
			}

			// mask-image: linear-gradient(to bottom, transparent 25%, black 75%);

			mask-image: linear-gradient(to bottom,
				rgba(0, 0, 0, 0) 5rem,
				rgba(0, 0, 0, 1) 6.5rem,
				rgba(0, 0, 0, 1) 95%,
				rgba(0, 0, 0, 0) 100%);

			@include transition-all(.125s);
			opacity: 0;
			transform: translateX(100%);
			// pointer-events: none !important;
			&.visible {
				opacity: 1;
				transform: translateY(0%) scale(1);
				// pointer-events: all !important;
			}
			
			.panelBody {
				color: white;

				max-width: 576px;
				width: 100%;
				min-height: calc(100% - 6.25rem);
				margin: 5.75rem auto 0rem;
				overflow: hidden;
				
				// color: white;
				// height: 100%;
				position: relative;

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;

				// background: rgba(0,0,0,0.45);
				// border-radius: 1rem;
		
					// display: flex;
					// flex-direction: row;
					// align-items: center;
					// justify-content: center;
		
				padding: 1rem 1rem 3rem 1rem;
				margin-bottom: .625rem;
					
				svg {
					// color: yellow;
					filter: drop-shadow(3px 3px 3px #000);
					margin: 0rem .25rem 0 .25rem;
					// transform: translateY(-.125rem)
				}

				// @media (min-width: 575px) {
				// 	border-radius: 1rem;
				// }

				
				.marketHeader {
					margin: 0;
				}

				.item {
					background: rgba(0,0,0,0.45);
					border-radius: 1rem;
					padding: .5rem .5rem .5rem .25rem;
					margin-bottom: .5rem;

					max-width: calc(576px - 3rem);
					width: calc(100% - 3rem);
					// margin: 0 4rem .5rem;

					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;

					line-height: 1.0;

					.iconWrap {
						width: 64px;
						margin-right: .25rem;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							max-width: 64px;
							max-height: 32px;
						}
					}

					.itemName {
						text-align: left;
						flex: 1;
					}

					.control {
						// justify-self: flex-end;
						margin: 0rem .5rem 0 0;

						svg {
							transform: translateY(0rem);
						}

						&.active {
							svg {
								color: yellow;
							}
						}

					}

					.cost {
						font-size: .75rem;

						color: yellow;
						text-shadow: 2px 2px 3px #000;
						
						// color: black;
						svg {
							margin: 0;
							// color: black;
							color: yellow;
							filter:drop-shadow(3px 3px 3px #000);

							transform: scale(.7) translateY(-.05rem);// translateX(2rem);
							margin-right: -.125rem;
						}
					}
				}
			}

			

			// p {
			// 	color: white;
			// 	max-width: 575px;
			// 	margin: 1.5rem 0;
			// 	text-shadow: 3px 3px 3px #000;

			// 	text-align: center;

			// 	font-size: 1.35rem;
			// }

			// p + p {
			// 	margin-top: 0;
			// }

			// svg {
			// 	color: yellow;
			// 	filter: drop-shadow(0px 0px 7px #000);
			// 	// animation: pulse 1.5s ease 0s infinite;
			// }
		}
	}
}