
.appStoreLinks {

	display: flex;
	flex-direction: column;
	align-items: center;

	padding-bottom: 1.5rem;

	label {
		font-size: .8rem;
	}

	a {
		img {
			height: 48px;
		}

		&:first-of-type {
			margin-right: .5rem;
		}
	}
}