body {
	background: black;	
}

@font-face {
    font-family: 'Dimbo-Regular';
    src:url('./webfonts_Dimbo-Regular/Dimbo-Regular.ttf.woff') format('woff'),
        url('./webfonts_Dimbo-Regular/Dimbo-Regular.ttf.svg#Dimbo-Regular') format('svg'),
        url('./webfonts_Dimbo-Regular/Dimbo-Regular.ttf.eot'),
        url('./webfonts_Dimbo-Regular/Dimbo-Regular.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}