@import '../../../components/KittyGameView/styles/common';
@import '../../../components/KittyGameView/styles/_include-media';

.widget {
	opacity: 0.0; 
	// transform: translateY(-50%);
	@include transition-all(.5s);
	&.visible {
		opacity: 1;
		// transform: none;

		.panel, 
		.buttonPanel {
			pointer-events: all !important;
		}
	}

	max-width: 576px;
	width: 100%;
	// NB height is not relevant since panels are absolute positioned
	// height: 0%;
	margin: 0 auto;
	overflow: hidden;

	--panel-bottom: 70%;

	&.hasNotch {
		.panel .panelBody {
			padding-top: 2rem;
		}
		--panel-bottom: 60%;
		
	}

	
	.buttonPanel {
		
		position: absolute;
		right: 1rem;
		bottom: var(--panel-bottom);

		z-index: 15; 
		
		display: flex;
		flex-direction: row;
		align-items: center;

		.button {
			margin: 0 .33rem 0 .75rem;
		}
	}

	// &.visible {
	// 	.closeButton {
	// 		svg {
	// 			color: white;
	// 			transform: translateY(0) scale(1);
	// 		}
	// 	}
	// }
	

	.panel {

		overflow-y: auto;
		position: absolute;
		top: -2px; // rounding errors somehow when 0rem, and -1px has some sort of error too, so -2px fixes things
		left: 0;
		bottom: var(--panel-bottom);
		// right: -17px;
		background: rgba(0,0,0,0.5);

		mask-image: linear-gradient(to bottom,
			rgba(0, 0, 0, 0) 0rem,
			rgba(0, 0, 0, 1) .1rem,
			rgba(0, 0, 0, 1) 82.5%,
			rgba(0, 0, 0, 0) 100%);
			
		z-index: 10;

		// hide scrollbars on 1.0 dpi devices
		right: -17px;
		padding-right: 17px;
			
		// undo the hiding on higher DPI devices
		@include media('retina2x') {
			right: 0;
			padding-right: 0;
		}


		.panelBody {
			color: white;

			max-width: 576px;
			width: 100%;
			min-height: 100%;
			height: auto;
			margin: 0rem auto 0rem;
			// overflow: hidden;
			
			// color: white;
			// height: 100%;
			position: relative;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;

			

			// background: rgba(0,0,0,0.45);
			// border-radius: 1rem;
	
				// display: flex;
				// flex-direction: row;
				// align-items: center;
				// justify-content: center;
	
			padding: 1rem 1rem 1rem 1rem;

			text-shadow: 3px 3px 3px #000;
			font-size: 1.2rem;
			text-align: center;

			&, h1, h2, h3, h4, h5 {
				line-height: 0.95;
			}
				
			svg {
				// color: yellow;
				filter: drop-shadow(3px 3px 3px #000);
				margin: 0rem .25rem 0 .25rem;
			}
		}
	}
}