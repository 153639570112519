
/* Shared */
.loginBtn {
	box-sizing: border-box;
	position: relative;
	/* width: 13em;  - apply for fixed size */
	margin: 0.2em;
	padding: .5rem .75rem .5rem 2.625rem;
	border: none;
	text-align: left;
	line-height: 34px;
	white-space: nowrap;
	border-radius: 0.2em;
	font-size: 1.3rem;
	color: #FFF;

	box-shadow: 3px 3px 15px rgb(0,0,0);
	@include noselect()

	// color: transparentize($content-color, .1);
	// border-radius: 1rem;
	@include transition-all(.2s);

	// Note must match bootstrap specificity to override without !important
	&:not(:disabled):not(:global(.disabled)):active {
		// background: transparentize($content-bg, .55);
		transform: scale(.95);
	}

	// background:   transparentize($norm, $bg-tx);
	// border-color: transparentize($norm, $br-tx);

	&:hover, &:focus {
		// box-shadow: 0 0 0 0.2rem transparentize($norm, $br-tx - .05);
	}

	&:not(:disabled):not(:global(.disabled)):active {
		// $dark: darken($norm, $dark-perc);

		// background:   transparentize($dark, $bg-tx);
		// border-color: transparentize($dark, $br-tx);
		// box-shadow: 0 0 0 0.2rem transparentize($dark, $br-tx - .05);
	}
}
.loginBtn:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
	width: 2rem;
	height: 100%;
}
.loginBtn:focus {
  	outline: none;
}
.loginBtn:active {
	box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1),
		0 0 .5rem 3px rgba(0,0,0,0.5);
}


/* Facebook */
.loginBtn--facebook {
	background-color: #4C69BA;
	background-image: linear-gradient(#4C69BA, #3B55A0);
	/*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
	text-shadow: 0 -1px 0 #354C8C;
}
.loginBtn--facebook:before {
	border-right: #364e92 1px solid;
	background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') no-repeat;
	background-position: .5rem .75rem;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
	background-color: #5B7BD5;
	background-image: linear-gradient(#5B7BD5, #4864B1);
}


/* Google */
.loginBtn--google {
	/*font-family: "Roboto", Roboto, arial, sans-serif;*/
	background: #DD4B39;
}
.loginBtn--google:before {
	border-right: #BB3F30 1px solid;
	background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
 	background: #E74B37;
}

.loginBtn--nofb {
	margin: 1rem;
	padding-left: 0.75rem;
}