@import '../../../components/KittyGameView/styles/common';
@import '../../../components/KittyGameView/styles/_include-media';

.stats {

	color: white;
	text-shadow: 3px 3px 3px #000;
	svg {
		// color: yellow;
		filter: drop-shadow(3px 3px 3px #000);
		margin: 0rem .25rem 0 .25rem;
		// transform: translateY(-.125rem)
	}


	.streak {
		position: relative;
		background: rgba(0,0,0,0.45);
		border-radius: 1rem;
	
		overflow: hidden;

		width: 95%;
		max-width: calc(576px - 2rem);

		padding: .35rem 1rem .35rem .5rem;
		margin-bottom: .5rem;

		&.first {
			margin-top: .5rem;
		}

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		/* used below so we don't have to repeat ourselves 3x */
		$branding-opacity: 0.9;
		

		.value {
			text-align: right;
			min-width: 3.5rem;
			/* padding-right: .75rem; */
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			line-height: 1.0;
			width: 40%;
			margin-right: -.75rem;


			.avg {
				display: block;
				font-size: .65rem;

			}

			.valueCurrent {
				color: $brand-primary;
				opacity: $branding-opacity;
			}
		}

		.name {
			text-align: right;
			background: transparent;
			line-height: .95;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
			width: 80%;
			padding-right: .5rem;
			position: relative;
			z-index: 5;

		}


		svg {
			position: absolute;
			// left: -.5rem;
			// right: 0rem;
			left: 0;
			z-index: 1;
			// width: calc(100% + .25rem);
			width: calc(100% - 27.5%);
			
			filter: drop-shadow(1px 1px 2px #000);
			
			margin: 0;
			
			// transform: translate(-27.5%, .075rem);
			// opacity: 0.5;

			mask-image: linear-gradient(to right,
				rgba(0, 0, 0, 0) 00%,
				rgba(0, 0, 0, 1) 33%,
				rgba(0, 0, 0, 1) 100%);

			circle {
				fill-opacity: 0 !important;
			}
				
			circle:last-of-type {
				stroke: $brand-primary !important;
				stroke-width: 3px !important;
				opacity: $branding-opacity;
			}

			circle, polyline {
				// transform: translate(-.125rem, -.125rem);
				transform: translate(-0.2rem, -0.05rem);
			}

			polyline {
				fill: none !important;

				&:last-of-type {
					stroke-width: 2px !important;
					stroke: $brand-primary !important;
					opacity: $branding-opacity;
				}
			}
		}

		&.record {
			color: yellow;

			.valueCurrent {
				color: yellow;
			}

			svg {
				circle:last-of-type {
					stroke: yellow !important;
				}
			}

			polyline:last-of-type {
				stroke: yellow !important;
			}
		}
	}
}