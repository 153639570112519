// .shop {
// 	:global(.list-group-item) {

// 		display: flex;
// 		flex-direction: row;
// 		justify-content: space-between;
// 		align-items: center;

// 		padding-right: 1rem;

// 		label {
// 			margin: 0;
// 		}

// 		&:first-child, &:last-child {
// 			border-radius: 0;
// 		}


// 	}
	
// }

@import '../../../components/KittyGameView/styles/common';
@import '../../../components/KittyGameView/styles/_include-media';

.shop {
	position: absolute;
	top: 0;
	left: 0;
	bottom: -2.5rem;
	right: 0;
	z-index: 100;

	// background: rgba(0,0,0,0.85);
	// color: white;

	&:not(.transparentBg) {
		
		background-color: $brand-secondary;
		background-image: linear-gradient(to bottom, #db4983, #ab6ac3, #5089db, #009ac8, #16a1a1);
		color: $brand-primary;

		.bg {

			position: absolute;
			top: -100%;
			left: -100%;
			bottom: -100%;
			right: -100%;
			
			
			background-position: 50% 50%;
			background-size: 120% 120%;
					
			animation:spin 180s linear infinite;
			@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
		}
	}
	

	@include transition-all(.5s);
	opacity: 0;
	pointer-events: none !important;

	&.visible {
		opacity: 1;
		pointer-events: all !important;
	}

	&.visible.textOverlay {
		pointer-events: none !important;
	}

	.streak-record {
		color: yellow !important;
	}
}