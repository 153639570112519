@import './customizeBootstrap';

.swal2-popup {

	.swal2-title  {
		color: black !important;
		font-weight: normal !important;
	}

	p {
		svg {
			transform: translateY(-.2rem);
			color: darken($brand-third, 12%);
			// margin: 0 .25rem 0 -.125rem;

		}
	}

	button.swal2-styled:not(.foobar) {
		@include stylize-button($content-bg);
		display: block;
		margin: .5rem 0;
		width: 100%;
		line-height: 1;
	}

	button.swal2-styled.swal2-confirm {
		@include stylize-button($brand-primary);
		background: $brand-primary;
		color: #fff;
	}

	button.swal2-styled.swal2-confirm-secondary {
		@include stylize-button($brand-secondary);
		background: $brand-secondary;
		color: #fff;
	}

	.form-control.swal-custom-cc-input {
		padding-top: 32px/1.5 * 1.125;
		
		@include media('retina2x') {
			padding-top: 24px/2 * 1.5;
		}

		@include media('retina3x') {
			padding-top: 24px/2 * 1.5;
		}
	}
}

#game-wrap,
#game-container,
#react-view-overlay,
#basic-loading-indicator {
	
	display: flex;
	justify-content: center;
	align-items: center;
	
	// Include this to trigger to load - span also required below
	&, & * { 
		font-family: Dimbo-Regular, sans-serif;
	}

		
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 0;
}

#game-container {
	z-index: 1;

	color: white;
}

#react-view-overlay {
	z-index: 2;

	// Must have defined position for z-index to work
	position: fixed;

	// Because the overlay is over the ENTIRE game,
	// let the mouse/touch pass thru.
	pointer-events: none;

	// However, for immediate children of the overlay,
	// turn on pointer events again. It's up to the children
	// to turn OFF pointer events if they don't want them
	& > * {
		pointer-events: all;
	}
}

#basic-loading-indicator {
	background-color: $brand-secondary;
	background-image: linear-gradient(to bottom, #f9d453, #f8c046, #f7ac3d, #f39837, #ef8434);

	z-index: 3;
	color: $brand-primary;

	flex-direction: column;

	p {
		color: white;
		max-width: 575px;
		margin: 1.5rem;
		text-shadow: 3px 3px 3px #000;

		text-align: center;
	}

	p + p {
		margin-top: 0;
	}

	svg {
		color: yellow;
		filter: drop-shadow(0px 0px 7px #000);
		// animation: pulse 1.5s ease 0s infinite;
	}


	// svg:after {
	// 	content:"";
	// 	display:block;
	// 	width: 3rem;
	// 	height: 3rem;
	// 	border-radius: 50%;
	// 	border: 3px solid $brand-primary;
	// 	position:absolute;
	// 	top:  -5px;
	// 	left: -5px;
		
	// }


	@keyframes pulse {
		0% {
			opacity: 1;
			transform: scale(1);
		}
		80% {
			opacity: 0.9;
			transform: scale(1.2);
		}
		100% {
			opacity: 1;
			transform: scale(1);
		}
	}
	
  

	@include transition-all(.7s);
	&.loaded {
		opacity: 0;
		pointer-events: none;
	}
}


