@import '../../../../components/KittyGameView/styles/_include-media';
@import '../../../../components/KittyGameView/styles/common.scss';

@mixin card-button {
	color: white;
	text-decoration: none;

	font-size: .85rem;
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 0, 0.25);

	border-radius: .25rem;
	padding: .125rem .25rem;

	svg {
		color: yellow;
		margin: 0 .25rem 0 0;
	}
}

.btn {
	@include card-button();

	cursor: pointer;

	label {
		margin: 0;
		cursor: pointer !important;
	}

	svg {
		// transform: translateY(.25rem);
	}

	&.playBtn {
		// background: yellow;	
		background: rgba(0,0,0,0.5);
		box-shadow: 0 0 0 0.2rem rgba(255, 255, 0, 1.0);

		color: yellow;
		text-shadow: 3px 3px 3px #000;
		// -webkit-text-stroke: 1px black;

		// color: black;
		svg { 
			// color: black;
			color: yellow;
			filter:drop-shadow(3px 3px 3px #000);
			font-size: 1rem;

			margin: 0 .25rem;
		}

		font-size: 1.1rem;

		// padding: .25rem .625rem;
		padding: .25rem .5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin: 0 .25rem;
	}

	&.btnSecondary {
		box-shadow: none;
		text-shadow: 3px 3px 3px #000;
		svg {
			color: white;
			filter:drop-shadow(3px 3px 3px #000);
			font-size: 1rem;
		}

		font-size: 1rem;

		width: 3rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

}

.play {
	cursor: default;

	opacity: 0;
	pointer-events: none;
	transform: translateY(33%);

	@include transition-all(.33s);
	&.active {
		opacity: 1;
		pointer-events: all;
		transform: translateY(0%);
	}


	max-width: 576px;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;

	position: relative;
	
	// overflow: visible;
	@media (min-width: 575px) {
		overflow: hidden;
	}

	
	.content {

		opacity: 1;
		@include transition-all(.2s);
		&.contentHidden {
			opacity: 0;
		}
		
		// Use a separate element for padding because .content adjusts right padding to hide scrollbars on 1.0 DPI devices (e.g. desktops/laptops)		
		.padding {
			padding: .5rem .5rem 3rem;
			overflow: hidden;
		}

		// Setting this via px instead of rem because
		// the size of the kitty doesn't change based on root font size,
		// but 'rem' size does change, so to maintain spacing below
		// bottom of kitty and top of the content, we have to set the
		// size of this layer as pixels instead of REM
		$click-layer-height: 24px * 8;

		//$click-layer-height - (24px * 1.5),
		$mask: linear-gradient(to bottom,
			rgba(0, 0, 0, 0) .5rem, 
			rgba(0, 0, 0, 1) 2rem,
			rgba(0, 0, 0, 1) 95%,
			rgba(0, 0, 0, 0) 100%);

		mask-image: $mask !important;

		overflow-y: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		
		// hide scrollbars on 1.0 dpi devices
		right: -17px;
		padding-right: 17px;
		
		// undo the hiding on higher DPI devices
		@include media('retina2x') {
			right: 0;
			padding-right: 0;
		}


		.clickLayer {
			height: $click-layer-height;

			display: flex;
			flex-direction: row;
			justify-content: space-between;

			// Flex in case we add widgets here around the cat in the future
		}

		.mainHeaderTitle {
			display: flex;
			flex-direction: column;
			line-height: 1;
			justify-content: center;
			align-items: center;

			margin: 1.725rem 0 .25rem 0;

			.line1 {
				text-transform: uppercase;
				font-size: .625rem;
			}
			.line2 {
				font-size: 1.75rem;
			}
			.line3 {
				font-size: 1rem;
			}
		}

		.mainHeaderControls {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;


		}

		.cardListHeader {
			margin: .825rem 0 -.15rem .25rem;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
		}

		.loadingSpinner {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin: 1.95rem 1rem 1rem;
			opacity: 0.8;
			font-size: 2rem;
		}

		.hCardScroll {
			position: relative;

			display: flex;
			flex-wrap: nowrap;
			overflow-x: auto;

			margin: 0 -1rem -.75rem -1rem; 
			padding: .5rem 1.25rem .5rem;

			// padding: .75rem .75rem .75rem;

			$mask: linear-gradient(to right,
				rgba(0, 0, 0, 0) .25rem, 
				rgba(0, 0, 0, 1) 1.2rem,
				rgba(0, 0, 0, 1) 92%,
				rgba(0, 0, 0, 0) 97%);

			mask-image: $mask !important;

			// Reduces usability on desktop 
			// &::-webkit-scrollbar {
			// 	width: 0px;  /* remove scrollbar space */
			// 	background: transparent;  /* optional: just make scrollbar invisible */
			// }

			.hCardEndHack {
				flex: 0 0 auto;
				width: 1.5rem;
			}
			
			.hCard {
				flex: 0 0 auto;
				cursor: pointer;

				// min-width: 5rem;
				// max-width: 10rem;
				margin: 0 .625rem 0 0 !important;
				// max-height: 10rem;
				overflow-y: auto;
				overflow-x: hidden;

				// width: 3rem;
				// height: 4rem;

				font-size: .85rem;

				// background: rgba(0,0,0,0.95);
				background: transparentize($brand-secondary, 0.25); 

				border-radius: .25rem;

				box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
				
				.mainWrap {
					
					// This min-width is needed for cases where the .mainLabel doesn't
					// take up at least 4rem of space. Combined with width:100%
					// we can handle both long and short labels and still look right
					min-width: 4rem;
					 // this 100% width makes the main wrap (and the cover image)
					 // extend 100% of the horizontal space. This is needed if the
					 // .mainLabel has a bit too much content and takes more than 4rem of space
					width: 100%;
					height: 5rem;
					position: relative;
					overflow: hidden;

					img {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						// top: 0;
						// left: 0;
						// right: 0;
						// bottom: 0;
						width: 110%;
						height: 110%;
						object-fit: cover;

						&.marketImage {
							width: 90%;
							height: 90%;
							object-fit: contain;
						}
					}

					.mainLabel {
						margin: 0;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						width: 4rem;
						bottom: .2rem;


						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						// align-content: flex-end;

						line-height: .8;

						// top: 0;
						// left: 50%;
						// transform: translateX(-50%);
						text-align: center;

						font-size: .9rem;
						// margin: 0 .25rem;
						padding: 0 .25rem;
					}

					.lockCost {
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						width: 100%;
						text-align: center;
						
						font-size: .75rem;

						color: yellow;
						text-shadow: 2px 2px 3px #000;
						// -webkit-text-stroke: 1px black;

						.cost {}

						// color: black;
						svg { 
							// color: black;
							color: yellow;
							filter:drop-shadow(3px 3px 3px #000);

							transform: scale(.7) translateY(-.05rem);// translateX(2rem);
							margin-right: -.8rem;
						}
					}

					
					.playCircle, .lockCircle {
						position: absolute;
						top: 44%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: 1.0rem;
						background: rgba(0,0,0,0.5);
						border-radius: 50%;
						border: 3px solid white;
						width: 1.8rem;
						height: 1.8rem;

						display: flex;
						justify-content: center;
						align-items: center;

						svg {
							transform: translateX(.1rem) translateY(.05rem);
							color: white;
						}
					}

					.lockCircle svg {
						transform: translate(0, -.025rem);
					}
				}

				.progressBar {
					width: 100%;
					height: .125rem;
					background: rgba(0,0,0,0.75);

					.inner {
						background: $brand-primary;
						// height: .2rem;
						height: 100%;
						width: 80%;
					}
				}

				.mainFooter {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding: .2rem .33rem;
					font-size: .75rem;

					background: rgba(0,0,0,0.75);

					label {
						margin: 0;

						&.costLabel {
							font-size: .75rem;

							color: yellow;
							text-shadow: 2px 2px 3px #000;
							// -webkit-text-stroke: 1px black;

							.cost {}

							// color: black;
							svg { 
								// color: black;
								color: yellow;
								filter:drop-shadow(3px 3px 3px #000);

								transform: scale(.7) translateY(-.05rem);// translateX(2rem);
								margin-right: -.8rem;
								margin-left: inherit;
							}
						}
					}

					svg {
						color: white;
						transform: translateY(.175rem) ;//translateX(.125rem);
						margin-left: .25rem;
					}
				}
			}
		}

		.stats-card {
			background: rgba(0,0,0,0.45);
			border-radius: 1rem;

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			padding: 1rem;
			margin-bottom: .625rem;
			
			svg {
				color: yellow;
				filter: drop-shadow(3px 3px 3px #000);
				margin: 0rem .25rem 0 .25rem;
				transform: translateY(-.125rem)
			}
		}


		.world-card {
			position: relative;
			background: rgba(0,0,0,0.45);
			border-radius: 1rem;

			padding: 1rem;
			overflow: hidden;

			& + .world-card {
				margin-top: .625rem;
			}

			&.--hidden {
				display: none;
			}

			// Use this so <a href='#...worldId'> links from ResumeCard make the world card land 
			// a "good" spot on the screen
			.anchor-jump {
				position: absolute;
				top: -4rem;
				opacity: 0.001;
			}

			.wc-level-list {
				@include transition-all(.2s);
				opacity: 0;
				transform: translateX(50%);
				pointer-events: none;

				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
				overflow: hidden;


				&.--visible {
					opacity: 1;	
					transform: translateX(0);
					pointer-events: all;
				}

				padding: 1rem 1rem;

				svg {
					margin: 0;
				}

				.wc-level-list-wrap {

					padding: 0 1rem 1rem 1rem;

					// background: red;
					position: absolute;
					top: 2.75rem;
					// right: 0;
					left: 0;
					bottom: 0;

					overflow-y: auto;

					// hide scrollbars on 1.0 dpi devices
					right: -17px;
					padding-right: calc(17px + .6rem); // logic would say +1rem, but testing shows .6rem gives correct alignment
					
					// undo the hiding on higher DPI devices
					@include media('retina2x') {
						right: 0;
						padding-right: 1rem;
					}


					$mask: linear-gradient(to bottom,
						rgba(0, 0, 0, 0) 0.5%, 
						rgba(0, 0, 0, 1) 2%,
						rgba(0, 0, 0, 1) 95%,
						rgba(0, 0, 0, 0) 99%);

					mask-image: $mask !important;
				}

				// reset
				ul, li {
					list-style: none;
					margin: 0;
					padding: 0;
				}

				ul {
					margin-top: .25rem;
				}

				li {
					margin-left: .25rem;

					display: flex;
					flex-direction: row;
					svg {
						margin: .25rem .5rem 0 0;
					}

					.completion, .time {
						width: 3rem;
						text-align: right;
						font-size: .85rem;
						margin: .125rem 0 0 0;

						svg {
							// margin: 0 .33rem 0 0;
							margin: 0 0 0 0;
							transform: translateY(-.125rem);
						}

						// background:white;
					}

					.time {
						// background: blue;
						width: 4rem;
					}

					.name {
						// margin-left: .1rem;
						width: 4rem;
						// background:red;
					}

					&.completed {
						opacity: 0.5;
						svg { //}, .completion, .name {
							// color: $brand-secondary;
							color: yellow;
						}
					}

					&.current {
						svg { //}, .completion, .name {
							color: yellow;
						}

						box-shadow: 0 0 0 0.2rem rgba(255, 255, 0, 0.25);

						border-radius: .25rem;
						padding: .125rem .25rem;

						margin: .25rem 0 .25rem 0;
					}


					&.locked {
						opacity: .8;

						.completion {
							text-align: center;
						}
					}
				}

				.back-btn {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;

					box-shadow: 0 0 0 0.2rem rgba(255, 255, 0, 0.25);

					border-radius: .25rem;
					padding: .125rem .25rem;

					svg {
						color: yellow;
					}

					.label {
						width: 3.25rem;
						// text-align: center;
						// font-size: .85rem;
						margin: 0 0 0 .5rem;
					
					}
					
					
				}
			}

			&.unlocked .wc-body .wc-body-left {
				width: 60%;
			}

			.wc-body {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				@include transition-all(.2s);
				opacity: 0;
				transform: translateX(-50%);

				&.--visible {
					opacity: 1;
					transform: translateX(0);
				}

				.wc-body-left {
					display: flex;
					flex-direction: column;
				}

				.wc-body-right {
					width: 40%;
					margin-left: .5rem;
					color: white;

					svg {
						color: yellow;
						margin: 0 .25rem 0 0;
					}

					.play-btn, .more-btn, .world-completed-notice {
						@include card-button();

						&.more-btn {
							margin: .75rem 0 .5rem;
						}

						&.world-completed-notice {
							background: rgba(0,0,0, 0.25);
							box-shadow: 0 0 0 0.2rem rgba(0,0,0, 0.25);
							// text-align: center;

							svg {
								color: white;
							}
						}

					}

					.time-info,
					.completion-info,
					.rate-info {
						font-size: .625rem;
						padding-left: .55rem; // better visual alignment with "all levels" button

						.hv {
							font-size: .85rem;
						}


						svg {
							color: yellow;
							margin: 0 0 0 0;
						}
					}
				}
			}

			display: flex;
			flex-direction: column;

			.header {
				display: flex;
				flex-direction: row;
				// justify-content: space-between;

				.left {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 3.25rem;

					margin: 0 .25rem 0 .25rem;

					.cost-content {
						margin-top: .5rem;

						label {
							font-size: .80rem;
						}

						svg {
							color: yellow;
							margin: -.75rem .25rem 0 -.5rem;
						}

						display: flex;
						flex-direction: row;
						justify-content: flex-end;
						align-items: center;
						flex-wrap: nowrap;
					}
				}
			}

			svg {
				filter: drop-shadow(3px 3px 3px #000);
				margin: .625rem .5rem 0 0;
			}

			&.locked {
				h2, .description {
					filter: opacity(.4);
				}
			}

			h2, h3 {
				width: 100%;
				font-size: 1.75rem;
				line-height: 1.01;
			}

			.description {
				line-height: 1.01;
			}

			&.resume-card, 
			&.tutorial-card:not(.--resume) {
				.left {
					margin: 0 .25rem 0 .25rem;
				}
				
				svg {
					color: yellow;
				}
			}

			&.tutorial-card.--resume {
				h2, h3 { 
					margin: 0;
				}
			}

			svg.redo-icon {
				margin: .4rem 0 0 .25rem !important;
			}

			&.resume-card {
				.world-info-link, .unlock-button {
					@include card-button();

					text-align: center;
					margin: .85rem 0 0 0;
					width: 100%;
				}

				.world-info-link {
					// margin-bottom: 0;
					svg {
						// icon is on the right for this element, so pad left instead of default pad right
						margin: 0 0rem 0 0.25rem;
					}
				}
			
			
				svg.redo-icon {
					// margin: .85rem 0 0 .25rem !important;

					position: absolute;
					top: 3rem;
					left: .75rem;
				}
			}

		}
	}
}