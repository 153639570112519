@import '../../../components/KittyGameView/styles/common';
@import '../../../components/KittyGameView/styles/_include-media';

#level-end-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	
	background-color: $brand-secondary;
	background-image: linear-gradient(to bottom, #db4983, #ab6ac3, #5089db, #009ac8, #16a1a1);
	color: $brand-primary;

	.content {
		max-width: 576px;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		overflow: auto;

		// hide scrollbars on 1.0 dpi devices
		right: -17px;
		padding-right: 17px;
		
		// undo the hiding on higher DPI devices
		@include media('retina2x') {
			right: 0;
			padding-right: 0;
		}

		// mask-image: linear-gradient(to bottom, transparent 25%, black 75%);

		$mask: linear-gradient(to bottom,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 1) 1rem,
			rgba(0, 0, 0, 1) 95%,
			rgba(0, 0, 0, 0) 100%);

		mask-image: $mask;
		-webkit-mask-image: $mask;		
		
		.content-padding {
			padding: 1.75rem 1rem 4rem;

			color: white;
			min-height: 100%;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			

			p, h1, h2, h3, h4, h5 {
				color: white;
				max-width: 575px;
				margin: 1.5rem 0;
				line-height: .95;
				text-shadow: 3px 3px 3px #000;

				text-align: center;

			}

			h1, h2, h3, h4, h5 {
				margin: 0;
			}

			p {
				font-size: 1.35rem;
				margin: .35rem 1rem;
			}

			

			p + p {
				margin-top: 0;
			}

			svg {
				color: yellow;
				filter: drop-shadow(0px 0px 7px #000);
				// animation: pulse 1.5s ease 0s infinite;
			}

			.level-end-button {
				svg {
					color: white;
				}
				position: absolute;
				bottom: 1.5rem;
				left: 50%;
				transform: translateX(-50%) scale(1.5);
				z-index: 99;

			}
		}
	}

	@include transition-all(.5s);
	opacity: 0;
	// pointer-events: none;

	&.visible {
		opacity: 1;
		pointer-events: all;
	}

	.streak-record {
		color: yellow !important;
	}
}